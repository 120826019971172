<template>
  <div
    style="width: 100%"
    :style="bgStyle"
    class="d-flex flex-column align-center justify-end pa-4 rounded-lg"
  >
    <v-card style="width: 100%" elevation="0" class="rounded-lg">
      <v-card-text class="d-flex flex-column">
        <template v-if="party?.locationReveled && !!party?.Address">
          <h6>{{ party?.Address.name }}</h6>

          <p class="text-truncate mb-0">
            {{ fullAddress }}
          </p>
          <v-btn @click="openMaps" small block color="primary" class="mt-2">
            Ver no mapa
          </v-btn>
        </template>
        <template v-else-if="!!party?.Address">
          <h6>Endereço não revelado</h6>
          <p class="text-truncate mb-0">
            {{ fullAddress }}
          </p>
        </template>
        <template v-else>
          <v-skeleton-loader v-if="loading" type="heading" class="mb-0" height="22"/>

          <h6 v-else class="mb-0">Endereço não informado</h6>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
const { VUE_APP_MAPS_KEY } = process.env;

import DescriptionWrap from "../global/DescriptionWrap.vue";

export default {
  components: { DescriptionWrap },
  computed: {
    fullAddress() {
      if(!this.party?.Address) return "";
      const addrs = this.party.Address;
      var address = "";
      if (this.party.locationReveled) {
        address += `${addrs.street}`;
        if (addrs.number) address += `, ${addrs.number}`;
        address += ` - ${addrs.neighborhood}`;
        if (addrs.complement) address += ` - ${addrs.complement}`;
        address += ` - ${addrs.city} - ${addrs.state}`;
        if (addrs.zipCode) address += ` - ${addrs.zipCode}`;
      } else {
        address = `${addrs.city} - ${addrs.state}`;
      }

      return address;
    },
    bgUrl() {
      if (!!this.party?.Address) {
        const url = new URL(`https://maps.googleapis.com/maps/api/staticmap`);
        url.searchParams.set("center", this.fullAddress);
        url.searchParams.set("zoom", this.party?.locationReveled ? "14" : "11");
        url.searchParams.set("size", "900x295");
        url.searchParams.set("key", VUE_APP_MAPS_KEY);
        if (this.party?.locationReveled)
          url.searchParams.set("markers", `color:blue|${this.fullAddress}`);
        if (this.$vuetify.theme.dark)
          url.searchParams.set("map_id", "63f4baff8ff4ba69");

        return `url(${url.toString()})`;
      } else
        return "url(https://th.bing.com/th/id/R.5340efb1b2c7fdeec32fcb0670061d0b?rik=Y0C2wya1vF6v2Q&riu=http%3a%2f%2fwww.bartoni.it%2fwritable%2fmod_sezioni%2f20190516180504-2019-50983-NDP.jpg&ehk=sGDNrNyQr%2fI5we2PntlVcst6pdprdmK6AOjrB%2bb5QUM%3d&risl=&pid=ImgRaw&r=0)";
    },
    bgStyle() {
      return {
        backgroundImage: this.bgUrl,
        "min-height": !!this.party?.Address ? "325px" : "auto",
        backgroundPosition: "center top",
        backgroundSize: "cover",
      };
    },
  },
  methods: {
    openMaps() {
      let address = this.fullAddress;
      let url = `https://www.google.com/maps/dir/?api=1&parameters&destination=${address}`;
      window.open(encodeURI(url), "_blank").focus();
    },
  },
  props: {
    party: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
