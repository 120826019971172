<template>
  <v-card light :id="`container-${containerId}`">
    <div
      class="grey lighten-2"
      style="overflow: hidden; width: 100%; height: 400px"
      :style="{ height: canvasSize.height + 'px' }"
      :id="containerId"
    />
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      absolute
      timeout="4000"
      top
    >
      {{ snackbar.text }}
    </v-snackbar>
    <div
      class="d-flex align-center gap-2 grey px-4 lighten-3"
      :class="$vuetify.theme.dark ? 'l-4' : ''"
    >
      <div class="d-flex gap-4" v-if="colorMode === 'available'">
        <div
          v-for="(label, key) in statusLabels"
          :key="key"
          class="d-flex gap-2 align-center"
        >
          <v-avatar size="12" :color="statusColors[key]" />
          <p class="mb-0 text-center">{{ label }}</p>
        </div>
      </div>
      <div
        class="d-flex flex-wrap gap-x-4 gap-y-1"
        v-if="colorMode === 'color'"
      >
        <div
          v-for="group in tableMap.Groups"
          :key="group.id"
          class="d-flex gap-2 align-center"
        >
          <v-avatar
            size="12"
            :color="group.color"
            :class="group.options.format === 'square' ? 'rounded-sm' : ''"
          />
          <p class="mb-0 text-center">{{ group.name }}</p>
        </div>
      </div>

      <!-- <v-spacer />
      <v-btn
        @click="zoomOut"
        :disabled="loading || map?.getZoom() <= editorProps.minZoom"
        small
        icon
      >
        <v-icon small>mdi-magnify-minus</v-icon>
      </v-btn>
      <v-btn
        @click="zoomIn"
        :disabled="loading || map?.getZoom() >= editorProps.maxZoom"
        small
        icon
      >
        <v-icon small>mdi-magnify-plus</v-icon>
      </v-btn> -->
    </div>
  </v-card>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";

export default {
  metaInfo: {
    meta: [
      {
        name: "viewport",
        content: "user-scalable=no, width=device-width, initial-scale=1",
      },
    ],
  },
  name: "TableSelector",
  props: {
    containerId: {
      type: String,
      default: "map-canvas",
    },
    value: {
      type: Object,
      default: null,
    },
    filterGroup: {
      type: String,
      default: null,
    },
    colorMode: {
      type: String,
      default: "color",
    },
    tableMap: {
      type: Object,
      required: true,
      default: () => ({
        backgroundUrl: null,
        Groups: [],
      }),
    },
    tooltip: {
      type: Function | String | Boolean,
      default: () => (table, group) => {
        return `<b>${group.name} • ${table.name}</b><br /> <small>${group.capacity} pessoas</small>`;
      },
    },
    fixedTooltip: {
      type: Boolean,
      default: false,
    },
    party: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: true,
    map: null,
    snackbar: {
      show: false,
      text: "",
      color: "",
    },

    formatsIcons: {
      square: "mdi-square",
      circle: "mdi-circle",
      hexagon: "mdi-hexagon",
    },
    editorProps: {
      minZoom: -2,
      maxZoom: 5,
      zoomSnap: 0,
      arrowStep: 10,
      minSize: 8,
      maxSize: 96,
    },
    canvasSize: {
      aspectRatio: 4 / 3,
      width: 800,
      height: 600,
    },
    statusColors: {
      available: "#4caf50",
      unavailable: "#f44336",
      reserved: "#ff9800",
      sold: "#f44336",
    },
    statusLabels: {
      available: "Disponível",
      reserved: "Reservado",
      sold: "Vendido",
    },
  }),
  methods: {
    zoomIn() {
      this.map.zoomIn();
    },
    zoomOut() {
      this.map.zoomOut();
    },

    // Draw and Canvas
    calculateCanvasSize() {
      const { aspectRatio } = this.canvasSize;
      const { offsetWidth } = document.getElementById(
        `container-${this.containerId}`
      );
      const canvasWidth = offsetWidth;
      const canvasHeight = canvasWidth / aspectRatio;
      this.canvasSize = {
        aspectRatio,
        width: canvasWidth,
        height: canvasHeight,
      };
    },
    async init() {
      this.calculateCanvasSize();
      if (this.map) this.map.remove();
      this.map = L.map(this.containerId, {
        crs: L.CRS.Simple,
        minZoom: this.editorProps.minZoom,
        maxZoom: this.editorProps.maxZoom,
        zoomSnap: this.editorProps.zoomSnap,
        zoom: 0,
        zoomControl: false,
        keyboard: false,

        // tilePane: 5,
        // overlayPane: 10,
        // shadowPane: 15,
        // markerPane: 20,
        // tooltipPane: 25,
        // popupPane: 30,
      });
      this.map.attributionControl.setPrefix(false);
      this.map.attributionControl.addAttribution(this.party.name);

      const bounds = [
        [0, 0],
        [this.canvasSize.height, this.canvasSize.width],
      ];
      this.map.setMaxBounds(bounds);

      try {
        await this.setBackground();
        this.drawTables();
      } catch (e) {
        console.log(e);
      }
    },
    async draw() {
      this.calculateCanvasSize();
      this.drawTables();
      await this.setBackground();
    },
    drawTables() {
      this.removeTableLayers();
      this.tables.forEach((table) => this.drawTable(table.group, table));
    },

    drawTable(group, table) {
      const size = group.options.size / 2;
      const { x, y } = table.position;
      const format = group.options.format;
      const selected =
        this.selectedTable?.id === table.id &&
        this.selectedTable?.group?.id === group.id;
      var el;
      var color = group.color;
      var disabled = false;
      if (this.filterGroup && this.filterGroup !== group.id) {
        return;
        disabled = true;
        color = "#ccc";
      } else if (this.colorMode === "available") {
        color = this.statusColors[table.status] || "#ccc";
        if (table.status !== "available") disabled = true;
      }

      if (format == "square")
        el = L.rectangle(
          [
            [x - size, y - size],
            [x + size, y + size],
          ],
          {
            color: selected ? "#6462e7" : color,
            fillColor: color,
            fillOpacity: 1,
          }
        );
      else
        el = L.circle([x, y], {
          color: selected ? "#6462e7" : color,
          fillOpacity: 1,
          fillColor: color,
          radius: size,
        });

      if (this.tooltip) {
        const tooltipText =
          typeof this.tooltip === "function"
            ? this.tooltip(table, group)
            : this.tooltip;
        el.bindTooltip(tooltipText, {
          permanent: this.fixedTooltip,
          direction: "top",
          className: "rounded-lg",
        }).addTo(this.map);
      }

      if (!disabled) el.on("click", () => this.selectTable(table, group));
    },
    setBackground() {
      return new Promise((resolve, reject) => {
        try {
          const { backgroundUrl } = this.tableMap;
          const img = new Image();
          const url = backgroundUrl;
          img.src = url;
          img.onload = () => {
            this.removeImageLayers();
            const bounds = [
              [0, 0],
              [img.height, img.width],
            ];

            const image = L.imageOverlay(url, bounds);
            image.addTo(this.map);
            const map = this.map;
            this.map.on("drag", function () {
              map.panInsideBounds(bounds, { animate: false });
            });

            this.map.setMaxBounds(bounds);
            this.map.fitBounds(bounds);
            this.$nextTick(() => {
              setTimeout(() => {
                this.map.invalidateSize();
                this.map.options.minZoom = this.map.getZoom();
              }, 100);
            });

            resolve();
          };
          img.onerror = reject;
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    removeImageLayers() {
      this.map.eachLayer((layer) => {
        if (layer instanceof L.ImageOverlay) {
          this.map.removeLayer(layer);
        }
      });
    },
    removeTableLayers() {
      this.map.eachLayer((layer) => {
        if (layer instanceof L.Circle || layer instanceof L.Rectangle) {
          layer.off("click");

          this.map.removeLayer(layer);
        }
      });
    },

    selectTable(table, group) {
      var data = table;
      data.group = group;

      this.selectedTable = data;
      // this.$nextTick(() => {
      //   this.drawTables();
      // });
    },
  },
  computed: {
    selectedTable: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit(
          "input",
          !value
            ? null
            : {
                ...value,
                group: {
                  ...value.group,
                  Tables: undefined,
                },
              }
        );
      },
    },
    tables() {
      return this.tableMap.Groups.flatMap((group) =>
        group.Tables.map((t) => {
          t.group = group;

          return new Proxy(t, {
            set: (target, key, value) => {
              if (key === "name") {
                target.name = value;
                this.drawTables();
              }
              if (key === "sellOnline") target.sellOnline = value;

              return true;
            },
          });
        })
      );
    },
  },
  watch: {
    tableMap: {
      handler() {
        this.draw();
      },
      deep: true,
    },
    filterGroup() {
      this.draw();
    },
    colorMode() {
      this.draw();
    },
    value() {
      this.drawTables();
    },
  },
  mounted() {
    this.init();
    window.addEventListener("resize", this.calculateCanvasSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculateCanvasSize);
  },
};
</script>

<style lang="scss">
.leaflet-interactive:focus {
  outline: none;
}
.leaflet-control-container {
  div {
    z-index: 3 !important;
  }
}
.leaflet-pane {
  z-index: 3 !important;
}
.leaflet-control {
  z-index: 4 !important;
}
</style>
